import { Photo } from '@/typings';

export const galleryPhotos: Photo[] = [
  {
    id: '1',
    alt: 'Koncert w kościele',
    path: '/images/gallery/img1.avif',
    path_f: '/images/gallery/img1_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '2',
    alt: 'Warsztaty chóralne',
    path: '/images/gallery/img2.avif',
    path_f: '/images/gallery/img2_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '3',
    alt: 'Zespół przed koncertem',
    path: '/images/gallery/img3.avif',
    path_f: '/images/gallery/img3_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '4',
    alt: 'Wiosenne warsztaty w Lanckoronie',
    path: '/images/gallery/img4.avif',
    path_f: '/images/gallery/img4_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '5',
    alt: 'Zespół przed koncertem w Rzeszowie',
    path: '/images/gallery/img5.avif',
    path_f: '/images/gallery/img5_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '6',
    alt: 'Koncert bożonarodzeniowy w kościele',
    path: '/images/gallery/img6.avif',
    path_f: '/images/gallery/img6_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '7',
    alt: 'Zespół przed koncertem',
    path: '/images/gallery/img7.avif',
    path_f: '/images/gallery/img7_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '8',
    alt: 'Zespół w kościele Bonifratrów obok baneru z napisem ars cameralis',
    path: '/images/gallery/img8.avif',
    path_f: '/images/gallery/img8_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '9',
    alt: 'Zespół z bazyliką archikatedralną we Fromborku w tle',
    path: '/images/gallery/img9.avif',
    path_f: '/images/gallery/img9_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '10',
    alt:
      'Zespół po koncercie w bazylice archikatedralnej we Fromborku w strojach galowych.',
    path: '/images/gallery/img10.avif',
    path_f: '/images/gallery/img10_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '11',
    alt:
      'Warsztaty realizowane w ramach programu "Małopolskie Chóry - Małopolska Rozśpiewana"',
    path: '/images/gallery/img11.avif',
    path_f: '/images/gallery/img11_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '12',
    alt: 'Chór po zakończonych warsztatach na schodach Gościńca pod Lubomirem.',
    path: '/images/gallery/img12.avif',
    path_f: '/images/gallery/img12_f.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
];

export const galleryMiniatures: Photo[] = [
  {
    id: '1',
    alt: 'Koncert w kościele',
    path: '/images/gallery/img1.avif',
    path_f: '/images/gallery/img1_f.webp',
    path_thumb: '/images/thumbs/img1_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '2',
    alt: 'Warsztaty chóralne',
    path: '/images/gallery/img2.avif',
    path_f: '/images/gallery/img2_f.webp',
    path_thumb: '/images/thumbs/img2_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '3',
    alt: 'Zespół przed koncertem',
    path: '/images/gallery/img3.avif',
    path_f: '/images/gallery/img3_f.webp',
    path_thumb: '/images/thumbs/img3_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '4',
    alt: 'Wiosenne warsztaty w Lanckoronie',
    path: '/images/gallery/img4.avif',
    path_f: '/images/gallery/img4_f.webp',
    path_thumb: '/images/thumbs/img4_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '5',
    alt: 'Zespół przed koncertem w Rzeszowie',
    path: '/images/gallery/img5.avif',
    path_f: '/images/gallery/img5_f.webp',
    path_thumb: '/images/thumbs/img5_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '6',
    alt: 'Koncert bożonarodzeniowy w kościele',
    path: '/images/gallery/img6.avif',
    path_f: '/images/gallery/img6_f.webp',
    path_thumb: '/images/thumbs/img6_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '7',
    alt: 'Zespół przed koncertem',
    path: '/images/gallery/img7.avif',
    path_f: '/images/gallery/img7_f.webp',
    path_thumb: '/images/thumbs/img7_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '8',
    alt: 'Zespół w kościele Bonifratrów obok baneru z napisem ars cameralis',
    path: '/images/gallery/img8.avif',
    path_f: '/images/gallery/img8_f.webp',
    path_thumb: '/images/thumbs/img8_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '9',
    alt: 'Zespół z bazyliką archikatedralną we Fromborku w tle',
    path: '/images/gallery/img9.avif',
    path_f: '/images/gallery/img9_f.webp',
    path_thumb: '/images/thumbs/img9_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '10',
    alt:
      'Zespół po koncercie w bazylice archikatedralnej we Fromborku w strojach galowych.',
    path: '/images/gallery/img10.avif',
    path_f: '/images/gallery/img10_f.webp',
    path_thumb: '/images/thumbs/img10_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '11',
    alt:
      'Warsztaty realizowane w ramach programu "Małopolskie Chóry - Małopolska Rozśpiewana"',
    path: '/images/gallery/img11.avif',
    path_f: '/images/gallery/img11_f.webp',
    path_thumb: '/images/thumbs/img11_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
  {
    id: '12',
    alt: 'Chór po zakończonych warsztatach na schodach Gościńca pod Lubomirem.',
    path: '/images/gallery/img12.avif',
    path_f: '/images/gallery/img12_f.webp',
    path_thumb: '/images/thumbs/img12_thumb.webp',
    type: 'image/avif',
    type_f: 'image/webp',
  },
];
