


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Photo } from '@/typings';

@Component({})
export default class GalleryTile extends Vue {
  @Prop({ type: Object, required: true }) item!: Photo;
  isImageLoaded: boolean = false;

  onImageLoad(): void {
    this.isImageLoaded = true;
  }
}
