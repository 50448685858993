























import { Component, Vue } from 'vue-property-decorator';
import ImageGallery from '@/components/gallery/ImageGallery.vue';
import GalleryTile from '@/components/gallery/GalleryTile.vue';
import { galleryMiniatures, galleryPhotos } from './galleryContent';
import { Photo } from '@/typings';

@Component({ components: { ImageGallery, GalleryTile } })
export default class Gallery extends Vue {
  activePhotoId: string = '';
  galleryMiniatures: Photo[] = galleryMiniatures;
  galleryPhotos: Photo[] = galleryPhotos;
  isGalleryOpen: boolean = false;

  openGallery(id: string): void {
    this.activePhotoId = id;
    this.toggleGallery();
  }

  toggleGallery(): void {
    this.isGalleryOpen = !this.isGalleryOpen;
  }
}
