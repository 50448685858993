



























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ArrowRight from 'vue-material-design-icons/ChevronRight.vue';
import ArrowLeft from 'vue-material-design-icons/ChevronLeft.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import Loader from '@/components/shared/Loader.vue';
import { Photo } from '@/typings';

@Component({ components: { ArrowRight, ArrowLeft, CloseIcon, Loader } })
export default class ImageGallery extends Vue {
  @Prop({ type: String, required: true }) startPhotoId!: string;
  @Prop({ type: Array, required: true }) galleryPhotos!: Photo[];

  @Emit('close') onGalleryClose(): void {
    return;
  }
  activePhotoId: string = '1';
  isImageLoaded: boolean = false;

  mounted(): void {
    this.activePhotoId = this.startPhotoId;
  }

  get activeImage(): Photo {
    return this.galleryPhotos.find((photo) => photo.id === this.activePhotoId)!;
  }

  get counter(): string {
    return `${this.imageIndex} / ${this.galleryPhotos.length}`;
  }

  get imageIndex(): number {
    const image: Photo = this.galleryPhotos.find(
      (photo) => photo.id === this.activePhotoId
    )!;
    return this.galleryPhotos.indexOf(image) + 1;
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }

  setNextImage(): void {
    this.isImageLoaded = false;
    const activeImage: Photo = this.galleryPhotos.find(
      (photo) => photo.id === this.activePhotoId
    )!;
    const index: number = this.galleryPhotos.indexOf(activeImage);
    if (index < this.galleryPhotos.length - 1) {
      this.activePhotoId = this.galleryPhotos[index + 1].id;
    } else {
      this.activePhotoId = this.galleryPhotos[0].id;
    }
  }

  setPreviousImage(): void {
    this.isImageLoaded = false;
    const activeImage: Photo = this.galleryPhotos.find(
      (photo) => photo.id === this.activePhotoId
    )!;
    const index: number = this.galleryPhotos.indexOf(activeImage);
    if (index > 0) {
      this.activePhotoId = this.galleryPhotos[index - 1].id;
    } else {
      this.activePhotoId = this.galleryPhotos[this.galleryPhotos.length - 1].id;
    }
  }
}
